var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "YesNoCard",
    {
      staticClass: "audio-check-card",
      attrs: {
        title: "Microphone Check",
        textHTML: _vm.textHTML,
        showYesNo:
          (!_vm.response || _vm.response === "yes") && !!_vm.audioTrack,
        showOkay: (_vm.errorHTML ? true : false) || _vm.response === "no"
      },
      on: { onYes: _vm.onYes, onNo: _vm.onNo, onOkay: _vm.onOkay },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [_c("v-icon", [_vm._v("fas fa-microphone")])]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _vm.microphones.length > 0
                ? _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "div",
                      { staticClass: "video-check-card__select-wrap" },
                      [
                        _c("v-select", {
                          staticClass:
                            "video-check-card__select v-select--theme--default",
                          attrs: {
                            value: _vm.microphone,
                            items: _vm.normalizedMicrophones,
                            dense: "",
                            "hide-details": ""
                          },
                          on: { input: _vm.microphoneInputHandler }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { "d-flex": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !!_vm.audioTrack,
                          expression: "!!audioTrack"
                        }
                      ],
                      staticClass: "sound-indicator-wrap"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "sound-indicator-border left" },
                        [
                          _c(
                            "div",
                            { staticClass: "sound-grid" },
                            _vm._l(20, function(i) {
                              return _c("span", { key: "span-1-" + i })
                            }),
                            0
                          ),
                          _c("div", {
                            ref: "left_bar",
                            staticClass: "sound-indicator"
                          })
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "sound-indicator-border right" },
                        [
                          _c(
                            "div",
                            { staticClass: "sound-grid" },
                            _vm._l(20, function(i) {
                              return _c("span", { key: "span-2-" + i })
                            }),
                            0
                          ),
                          _c("div", {
                            ref: "right_bar",
                            staticClass: "sound-indicator"
                          })
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("audio", { ref: "audio" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }