<template>
  <YesNoCard
    ref="card"
    class="video-check-card"
    :title="'Video Feed Check'"
    :textHTML="textHTML"
    :showYesNo="(!response || response === 'yes') && isMediaReady"
    :showOkay="(errorHTML ? true : false) || response === 'no'"
    :mode="modeOverride"
    :attempts="capturingAttempts"
    @onYes="onYes"
    @onNo="onNo"
    @onOkay="onOkay"
    @onReady="onReadyToTakePhoto"
    @onSave="onSave"
    @onDiscard="onDiscard"
    @onUpload="onUpload"
    @onSelect="onSelect"
    @onApply="onApply"
  >
    <template v-slot:icon>
      <v-icon>fas fa-video</v-icon>
    </template>
    <v-flex d-flex>
      <v-layout column>
        <v-flex shrink v-if="normalizedCameras.length > 0">
          <div class="video-check-card__select-wrap">
            <v-select
              :value="camera"
              :items="normalizedCameras"
              hide-details
              dense
              :disabled="isMediaReady === false"
              class="v-select--theme--default video-check-card__select"
              @input="cameraInputHandler"
            />
          </div>
        </v-flex>
        <v-flex d-flex>
          <div
            v-show="isMediaReady && !!videoTrack"
            class="ratio-container-wrap"
          >
            <RatioContainer>
              <div class="test-video-feed-wrap">
                <UserVideo
                  :track="videoTrack"
                  v-if="!!videoTrack"
                  ref="video"
                  @ready="onReady"
                />
                <div v-show="!!file" class="canvas-wrap">
                  <canvas ref="canvas" class="canvas" />
                </div>
                <div
                  v-if="selecting"
                  class="video-check-card__image-selection-container"
                >
                  <img
                    v-if="!!selectedImgUrl"
                    :src="selectedImgUrl"
                    class="video-check-card__selected-image"
                  />
                </div>
                <div v-if="canShowCountdown" class="photo-countdown">
                  {{ n }}
                </div>
                <v-flex
                  d-flex
                  align-center
                  v-if="uploading"
                  class="photo-upload"
                >
                  <v-progress-circular indeterminate class="spinner" />
                </v-flex>
                <v-flex
                  d-flex
                  align-center
                  v-if="!showDisclaimer"
                  class="video-check-card__disclaimer-link"
                >
                  <span @click="onDisclaimerLinkClick"> DISCLAIMER </span>
                </v-flex>
                <v-flex
                  d-flex
                  align-center
                  class="video-check-card__disclaimer"
                  :class="showDisclaimer ? 'show' : null"
                  @mouseleave="onDisclaimerMouseLeave"
                >
                  You approve that the photos and drawings generated may be used
                  for Go Remote marketing.
                </v-flex>
              </div>
            </RatioContainer>
          </div>
          <template v-if="selecting">
            <v-icon class="video-check-card__move-left" @click="onLeft">
              chevron_left
            </v-icon>
            <v-icon class="video-check-card__move-right" @click="onRight">
              chevron_right
            </v-icon>
          </template>
          <div v-if="!errorHTML && !isMediaReady" class="spinner-wrap">
            <v-progress-circular indeterminate class="spinner" />
            <div class="status-text">{{ status }}</div>
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
    <input
      type="file"
      ref="file"
      @change="previewFile"
      style="display: none"
      accept="image/x-png,image/gif,image/jpeg"
    />
  </YesNoCard>
</template>

<script>
import uniqid from "uniqid"
import { mapGetters, mapState } from "vuex"
import { uploadMedia } from "@/services/storage.service"
import YesNoCard from "@/components/GroupTeams/Common/YesNoCard"
import RatioContainer from "@/components/GroupTeams/Common/RatioContaier.vue"
import UserVideo from "@/components/GroupTeams/Common/User/UserVideo"
import { Math as Numbers } from "@/helpers"

const COUNTDOWN_MAX = 2
const BUFFERING_TIME = 20000 // 20 sec

const canvasToJpg = canvas =>
  new Promise(resolve =>
    canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.9)
  )

function drawImageScaled(img, ctx) {
  var canvas = ctx.canvas
  var hRatio = canvas.width / img.width
  var vRatio = canvas.height / img.height
  var ratio = Math.max(hRatio, vRatio)
  var centerShift_x = (canvas.width - img.width * ratio) / 2
  var centerShift_y = (canvas.height - img.height * ratio) / 2
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  ctx.drawImage(
    img,
    0,
    0,
    img.width,
    img.height,
    centerShift_x,
    centerShift_y,
    img.width * ratio,
    img.height * ratio
  )
}

export default {
  name: "VideoCheckCard",
  components: {
    YesNoCard,
    RatioContainer,
    UserVideo
  },
  props: {
    videoTrack: Object,
    errorHTML: String,
    cameras: {
      type: Array
    },
    camera: {
      type: String
    }
  },
  data() {
    return {
      showDisclaimer: false,
      showDisclaimerLink: true,
      response: null,
      isMediaReady: false,
      timer: null,
      n: COUNTDOWN_MAX,
      file: null,
      mode: "normal",
      uploading: false,
      capturingAttempts: 0,
      bufferingTimeout: null,
      selecting: false,
      selectedImgUrl: null
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  computed: {
    ...mapGetters("auth", ["user", "token", "client"]),
    ...mapState(["avatars"]),
    noUserPhoto() {
      return this.client && !!this.client.noUserPhoto
    },
    status() {
      if (!this.errorHTML && !this.videoTrack) {
        return "Connecting..."
      } else if (!this.errorHTML && this.videoTrack) {
        return "Buffering..."
      } else {
        return ""
      }
    },
    modeOverride() {
      if (this.selecting) {
        return "selecting"
      } else if (this.file) {
        if (this.fileUpload) {
          return "file-save"
        } else {
          return "save"
        }
      } else {
        return this.mode
      }
    },
    canShowCountdown() {
      return this.n > 0 && this.timer !== null
    },
    textHTML() {
      if (this.errorHTML) {
        return this.errorHTML
      } else if (this.response === "yes") {
        return "Good!"
      } else if (this.response === "no") {
        return "Sorry, please try <a target='_blank' href='https://www.thegogame.com/virtual-game-show-tech-support'>webcam support</a> and refresh your browser."
      } else if (this.isMediaReady) {
        return "Can you see yourself?"
      } else {
        return ""
      }
    },
    /**
     * @description Normalizes cameras to render as items in select.
     * @see https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
     */
    normalizedCameras() {
      return this.cameras.map((camera, index) => ({
        value: camera.deviceId,
        text: camera.label === "" ? `Camera ${index + 1}` : camera.label
      }))
    }
  },
  watch: {
    videoTrack(value) {
      clearTimeout(this.bufferingTimeout)
      if (value) {
        this.bufferingTimeout = setTimeout(this.onNo, BUFFERING_TIME)
      } else {
        this.isMediaReady = false
      }
    }
  },
  methods: {
    onLeft() {
      const index = this.avatars.findIndex(url => url === this.selectedImgUrl)
      let newIndex = index - 1
      if (newIndex < 0) {
        newIndex = this.avatars.length - 1
      }
      this.selectedImgUrl = this.avatars[newIndex]
    },
    onRight() {
      const index = this.avatars.findIndex(url => url === this.selectedImgUrl)
      let newIndex = index + 1
      if (newIndex > this.avatars.length - 1) {
        newIndex = 0
      }
      this.selectedImgUrl = this.avatars[newIndex]
    },
    onApply() {
      this.selecting = false
      let img = new Image()
      img.onload = async () => {
        const canvas = this.$refs.canvas
        const video = this.$refs.video.$el
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const ctx = canvas.getContext("2d")
        drawImageScaled(img, ctx)
        img = null
        this.file = await canvasToJpg(canvas)
      }
      img.src = this.selectedImgUrl
    },
    async previewFile(event) {
      const [file] = event.target.files
      const mb = file.size / 1024 / 1024
      if (mb > 10) return alert("The image is too big!")
      // console.log(file)
      let img = new Image()
      img.onload = async () => {
        const canvas = this.$refs.canvas
        const video = this.$refs.video.$el
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const ctx = canvas.getContext("2d")
        drawImageScaled(img, ctx)
        img = null
        this.file = await canvasToJpg(canvas)
      }
      img.src = URL.createObjectURL(file)
    },
    onSelect() {
      this.selecting = true
      this.selectedImgUrl = this.avatars[
        Numbers.getRandomInt(0, this.avatars.length - 1)
      ]
    },
    onUpload() {
      this.file = null
      this.$refs.file.value = ""
      this.$refs.file.click()
    },
    onDisclaimerLinkClick() {
      this.showDisclaimerLink = false
      this.showDisclaimer = true
    },
    onDisclaimerMouseLeave() {
      this.showDisclaimerLink = true
      this.showDisclaimer = false
    },
    async onSave() {
      this.uploading = true
      let image
      if (this.selectedImgUrl) {
        image = this.selectedImgUrl
      } else {
        try {
          const blob = this.file
          const fileName = `gamephotos/${uniqid()}-${this.user.username}.jpg`
          image = await uploadMedia({
            fileName,
            blob,
            token: this.token
          })
        } catch (e) {
          console.error(e)
          alert(
            "It looks like you are behind a firewall or VPN and therefore can't save your photo."
          )
          image = "https://jaznau.com/wp-content/uploads/2019/08/vpn.jpg"
        }
      }

      await this.$store.dispatch("updateUser", {
        userID: this.user.id,
        obj: { image }
      })

      this.uploading = false
      this.$emit("onYes")
    },
    onDiscard() {
      this.$refs.card.isCountdownOn = false
      this.file = null
      this.n = COUNTDOWN_MAX
    },
    async captureImage() {
      const canvas = this.$refs.canvas
      const video = this.$refs.video.$el
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const ctx = canvas.getContext("2d")
      ctx.translate(canvas.width, 0)
      ctx.scale(-1, 1)
      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      this.file = await canvasToJpg(canvas)
      this.capturingAttempts = this.capturingAttempts + 1
    },
    onTimer() {
      if (this.n > 0) {
        this.n--
      } else {
        this.captureImage()
        clearInterval(this.timer)
        this.timer = null
      }
    },
    onReadyToTakePhoto() {
      this.timer = setInterval(this.onTimer, 1000)
    },
    onReady() {
      clearTimeout(this.bufferingTimeout)
      this.isMediaReady = true
    },
    onYes() {
      this.response = "yes"
      if (this.noUserPhoto) {
        this.mode = "upload"
      } else {
        this.mode = "photo"
      }
    },
    onNo() {
      this.response = "no"
      this.$emit("onNo")
    },
    onOkay() {
      this.response = null
      this.$emit("onOkay")
    },
    cameraInputHandler(id) {
      this.response = null
      this.$emit("input:camera", id)
    }
  }
}
</script>
<style lang="scss">
.video-check-card {
  &__image-selection-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    background: #fff;
  }
  &__selected-image {
    object-fit: fill;
    max-width: 100%;
    max-height: 100%;
  }
  &__move-left,
  &__move-right {
    user-select: none;
    color: $primary_accent_color !important;
    font-size: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      color: $secondary_accent_color !important;
    }
  }
  &__move-left {
    left: 0;
  }
  &__move-right {
    right: 0;
  }
  .canvas-wrap {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .canvas {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .photo-countdown {
    color: $color-white;
    font-size: 54px;
    position: absolute;
    bottom: 3px;
    right: 5px;
  }
  .ratio-container-wrap {
    position: relative;
    flex: 0 0 auto !important;
    width: 22vh;
    max-width: 66%;
    margin: auto;
  }
  .test-video-feed-wrap {
    @extend .rtb-border, .rtb-border-radius;
    background-color: $primary_accent_color;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
  .spinner {
    display: block;
    color: $primary_accent_color;
    margin: auto;
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .spinner-wrap {
    align-self: center;
  }
  .status-text {
    margin-right: -6px;
  }
  .photo-upload {
    position: absolute;
    width: 100%;
    height: 100%;
    .spinner {
      margin-bottom: auto;
      margin-top: auto;
      color: #fff;
      opacity: 0.33;
    }
  }
}

.video-check-card {
  &__disclaimer {
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100%;
    font-size: 8px;
    height: 30px;
    padding-bottom: 1px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.6);
    &.show {
      bottom: 0;
    }
  }

  &__disclaimer-link {
    position: absolute;
    justify-content: left;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    padding-bottom: 1px;
    padding-left: 4px;
    & > span {
      cursor: pointer;
      font-size: 8px;
      text-decoration: underline;
      color: rgba(255, 255, 255, 0.65);
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
      flex: 0 0 auto !important;
      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }
  }

  &__select-wrap {
    width: 60%;
    margin: {
      right: auto;
      left: auto;
    }
  }

  &__select {
    .v-input input {
      height: 24px;
    }

    .v-select__selection {
      padding-left: 0;
      font-size: 12px;
    }

    .v-input__slot:before {
      display: none;
    }
  }
}
</style>
