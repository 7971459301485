var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "YesNoCard",
    {
      staticClass: "chose-team-card",
      attrs: {
        title: "Choose Team",
        showYesNo: true,
        mode: "chooseGame",
        showOkay: _vm.showOkay
      },
      on: { onYes: _vm.onYes, onNo: _vm.onNo, onOkay: _vm.onOkay },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [_c("v-icon", [_vm._v("fas fa-gamepad")])]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "", "align-center": "" } },
        [
          _vm.working
            ? _c(
                "v-layout",
                { staticClass: "spinner-container" },
                [
                  _c("v-progress-circular", {
                    staticClass: "spinner",
                    attrs: { indeterminate: "" }
                  })
                ],
                1
              )
            : _c(
                "v-layout",
                { staticClass: "ma-4", attrs: { column: "" } },
                [
                  _vm.listOfGames.length && _vm.listOfGames.length !== 1
                    ? _c("v-flex", { staticClass: "mb-2" }, [
                        _c(
                          "div",
                          [
                            _c("v-select", {
                              attrs: {
                                value: _vm.targetGameID,
                                items: _vm.listOfGames,
                                label: "Select Game"
                              },
                              on: { input: _vm.onChooseGame }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _c("v-flex", [
                    _c(
                      "div",
                      [
                        _c("v-select", {
                          attrs: {
                            value: _vm.targetTeamID,
                            items: _vm.listOfTeams,
                            disabled: !_vm.listOfTeams.length,
                            label: "Select Team"
                          },
                          on: { input: _vm.onChooseTeam }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }