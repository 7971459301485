var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "YesNoCard",
    {
      ref: "card",
      staticClass: "video-check-card",
      attrs: {
        title: "Video Feed Check",
        textHTML: _vm.textHTML,
        showYesNo:
          (!_vm.response || _vm.response === "yes") && _vm.isMediaReady,
        showOkay: (_vm.errorHTML ? true : false) || _vm.response === "no",
        mode: _vm.modeOverride,
        attempts: _vm.capturingAttempts
      },
      on: {
        onYes: _vm.onYes,
        onNo: _vm.onNo,
        onOkay: _vm.onOkay,
        onReady: _vm.onReadyToTakePhoto,
        onSave: _vm.onSave,
        onDiscard: _vm.onDiscard,
        onUpload: _vm.onUpload,
        onSelect: _vm.onSelect,
        onApply: _vm.onApply
      },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [_c("v-icon", [_vm._v("fas fa-video")])]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-flex",
        { attrs: { "d-flex": "" } },
        [
          _c(
            "v-layout",
            { attrs: { column: "" } },
            [
              _vm.normalizedCameras.length > 0
                ? _c("v-flex", { attrs: { shrink: "" } }, [
                    _c(
                      "div",
                      { staticClass: "video-check-card__select-wrap" },
                      [
                        _c("v-select", {
                          staticClass:
                            "v-select--theme--default video-check-card__select",
                          attrs: {
                            value: _vm.camera,
                            items: _vm.normalizedCameras,
                            "hide-details": "",
                            dense: "",
                            disabled: _vm.isMediaReady === false
                          },
                          on: { input: _vm.cameraInputHandler }
                        })
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "v-flex",
                { attrs: { "d-flex": "" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isMediaReady && !!_vm.videoTrack,
                          expression: "isMediaReady && !!videoTrack"
                        }
                      ],
                      staticClass: "ratio-container-wrap"
                    },
                    [
                      _c("RatioContainer", [
                        _c(
                          "div",
                          { staticClass: "test-video-feed-wrap" },
                          [
                            !!_vm.videoTrack
                              ? _c("UserVideo", {
                                  ref: "video",
                                  attrs: { track: _vm.videoTrack },
                                  on: { ready: _vm.onReady }
                                })
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !!_vm.file,
                                    expression: "!!file"
                                  }
                                ],
                                staticClass: "canvas-wrap"
                              },
                              [
                                _c("canvas", {
                                  ref: "canvas",
                                  staticClass: "canvas"
                                })
                              ]
                            ),
                            _vm.selecting
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "video-check-card__image-selection-container"
                                  },
                                  [
                                    !!_vm.selectedImgUrl
                                      ? _c("img", {
                                          staticClass:
                                            "video-check-card__selected-image",
                                          attrs: { src: _vm.selectedImgUrl }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _vm.canShowCountdown
                              ? _c("div", { staticClass: "photo-countdown" }, [
                                  _vm._v(" " + _vm._s(_vm.n) + " ")
                                ])
                              : _vm._e(),
                            _vm.uploading
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass: "photo-upload",
                                    attrs: { "d-flex": "", "align-center": "" }
                                  },
                                  [
                                    _c("v-progress-circular", {
                                      staticClass: "spinner",
                                      attrs: { indeterminate: "" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.showDisclaimer
                              ? _c(
                                  "v-flex",
                                  {
                                    staticClass:
                                      "video-check-card__disclaimer-link",
                                    attrs: { "d-flex": "", "align-center": "" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        on: { click: _vm.onDisclaimerLinkClick }
                                      },
                                      [_vm._v(" DISCLAIMER ")]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "v-flex",
                              {
                                staticClass: "video-check-card__disclaimer",
                                class: _vm.showDisclaimer ? "show" : null,
                                attrs: { "d-flex": "", "align-center": "" },
                                on: { mouseleave: _vm.onDisclaimerMouseLeave }
                              },
                              [
                                _vm._v(
                                  " You approve that the photos and drawings generated may be used for Go Remote marketing. "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.selecting
                    ? [
                        _c(
                          "v-icon",
                          {
                            staticClass: "video-check-card__move-left",
                            on: { click: _vm.onLeft }
                          },
                          [_vm._v(" chevron_left ")]
                        ),
                        _c(
                          "v-icon",
                          {
                            staticClass: "video-check-card__move-right",
                            on: { click: _vm.onRight }
                          },
                          [_vm._v(" chevron_right ")]
                        )
                      ]
                    : _vm._e(),
                  !_vm.errorHTML && !_vm.isMediaReady
                    ? _c(
                        "div",
                        { staticClass: "spinner-wrap" },
                        [
                          _c("v-progress-circular", {
                            staticClass: "spinner",
                            attrs: { indeterminate: "" }
                          }),
                          _c("div", { staticClass: "status-text" }, [
                            _vm._v(_vm._s(_vm.status))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("input", {
        ref: "file",
        staticStyle: { display: "none" },
        attrs: { type: "file", accept: "image/x-png,image/gif,image/jpeg" },
        on: { change: _vm.previewFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }