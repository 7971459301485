var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "YesNoCard",
    {
      staticClass: "sound-check-card",
      attrs: {
        title: "Headphone Check",
        textHTML: _vm.textHTML,
        showYesNo: (!_vm.response || _vm.response === "yes") && _vm.playedOnce,
        showOkay: _vm.response === "no"
      },
      on: { onYes: _vm.onYes, onNo: _vm.onNo, onOkay: _vm.onOkay },
      scopedSlots: _vm._u([
        {
          key: "icon",
          fn: function() {
            return [_c("v-icon", [_vm._v("fas fa-headphones")])]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.src
        ? _c("v-flex", { attrs: { "d-flex": "", "align-center": "" } }, [
            _vm.isPlaying
              ? _c("div", [
                  _c("img", {
                    attrs: {
                      width: "200",
                      src:
                        "https://media.giphy.com/media/3o85xCV3NjTFuLRa6s/giphy.gif"
                    }
                  })
                ])
              : _c("div", [
                  _c("div", { staticClass: "required-text" }, [
                    _vm._v(" Please put your"),
                    _c("br"),
                    _vm._v("headphones on now"),
                    _c("br"),
                    _vm._v("(optional) ")
                  ]),
                  _c(
                    "div",
                    { staticClass: "play-btn", on: { click: _vm.onPlay } },
                    [_c("v-icon", [_vm._v("play_arrow")])],
                    1
                  ),
                  _c("div", { staticClass: "play-text" }, [
                    _vm._v(" Click to test"),
                    _c("br"),
                    _vm._v(" your audio ")
                  ])
                ]),
            _c("audio", { ref: "audio", on: { ended: _vm.onEnd } }, [
              _c("source", { attrs: { src: _vm.src, type: "audio/mpeg" } }),
              _vm._v(" Your browser does not support the audio element. ")
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }