<template>
  <YesNoCard
    class="chose-team-card"
    :title="'Choose Team'"
    :showYesNo="true"
    :mode="'chooseGame'"
    :showOkay="showOkay"
    @onYes="onYes"
    @onNo="onNo"
    @onOkay="onOkay"
  >
    <template v-slot:icon>
      <v-icon>fas fa-gamepad</v-icon>
    </template>
    <v-flex d-flex align-center>
      <v-layout class="spinner-container" v-if="working">
        <v-progress-circular indeterminate class="spinner" />
      </v-layout>
      <v-layout column class="ma-4" v-else>
        <v-flex
          class="mb-2"
          v-if="listOfGames.length && listOfGames.length !== 1"
        >
          <div>
            <v-select
              :value="targetGameID"
              :items="listOfGames"
              label="Select Game"
              @input="onChooseGame"
            />
          </div>
        </v-flex>
        <v-flex>
          <div>
            <v-select
              :value="targetTeamID"
              :items="listOfTeams"
              :disabled="!listOfTeams.length"
              label="Select Team"
              @input="onChooseTeam"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-flex>
  </YesNoCard>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import YesNoCard from "@/components/GroupTeams/Common/YesNoCard"
import { fetchGames, fetchGameTeamsObject } from "@/services/game.service"

export default {
  name: "ChooseTeamCard",
  components: {
    YesNoCard
  },
  data() {
    return {
      games: {},
      targetTeamID: null,
      targetGameID: null,
      teams: {},
      working: false
    }
  },
  async created() {
    this.working = true
    try {
      this.games = await fetchGames({
        orgID: this.orgID,
        clientID: this.client.id
      })
      if (this.listOfGames.length === 1) {
        this.targetGameID = this.listOfGames[0].value
        this.teams = await fetchGameTeamsObject({
          gameID: this.targetGameID,
          orgID: this.orgID
        })
      }
    } catch (e) {
      console.warn(e)
    }
    this.working = false
  },
  computed: {
    ...mapGetters("auth", ["client"]),
    ...mapGetters(["user", "game"]),
    ...mapGetters(["orgID"]),
    listOfGames() {
      return Object.entries(this.games)
        .filter(
          entry =>
            entry[1].runStatus != "Deleted" &&
            !entry[1].endTimestamp &&
            !entry[1].ondeck &&
            !entry[1].deletedTimestamp &&
            !entry[1].deactivate &&
            (!entry[1].gameType || entry[1].gameType === "Standard")
        )
        .map(([key, value]) => ({
          value: key,
          text: value.externalName || value.name
        }))
    },
    listOfTeams() {
      return Object.entries(this.teams || {})
        .filter(entry => entry[1].show)
        .map(([key, value]) => ({
          value: key,
          text: value.name
        }))
    },
    showOkay() {
      return !!(this.user && this.targetTeamID && !this.working)
    }
  },
  methods: {
    ...mapActions(["updateUserToTeamID"]),
    ...mapActions("auth", ["updateLastKnownCheck", "initializeToGame"]),
    async onChooseGame(gameID) {
      this.working = true
      try {
        this.teams = await fetchGameTeamsObject({ gameID, orgID: this.orgID })
        this.targetGameID = gameID
      } catch (e) {
        console.error(e)
      }
      this.working = false
    },
    onChooseTeam(teamID) {
      this.targetTeamID = teamID
    },
    onYes() {
      this.$emit("onYes")
    },
    onNo() {
      this.$emit("onNo")
    },
    async onOkay() {
      this.working = true
      try {
        await this.initializeToGame({
          gameID: this.targetGameID,
          clientID: this.client.id
        })
        await this.updateUserToTeamID({
          teamID: this.targetTeamID,
          userID: this.user.id
        })
      } catch (e) {
        console.error(e)
      }
      this.working = false
      this.$emit("onOkay")
    }
  }
}
</script>

<style lang="scss">
.spinner-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: $primary_accent_color;
  z-index: 1;

  .spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
