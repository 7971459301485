<template>
  <v-container
    ma-0
    pa-0
    class="rtb-welcome-page"
    style="z-index: 1000"
    fill-height
    fluid
    text-xs-center
  >
    <v-layout column>
      <!-- Header -->
      <v-flex shrink class="header">
        <v-layout>
          <div class="logout" @click="logout()">LOG OUT</div>
        </v-layout>
        <div class="rtb-logo-wrap">
          <img width="270" :src="$theme.getImage('loginLogo')" />
        </div>
        <div class="team-tag">
          <div class="team-tag-team">
            <SvgTriangle class="team-shape-left"></SvgTriangle>
            <div class="team-tag-team-name">
              <UserName
                v-if="!!user"
                :firstname="user.firstname"
                :lastname="user.lastname"
              />
            </div>
            <SvgTriangle class="team-shape-right"></SvgTriangle>
          </div>
        </div>
        <div v-if="isAudit" class="audit-label">
          <span>Auditing</span>
        </div>
        <div v-if="!!game" class="welcome-text">
          Welcome to {{ game.externalName || game.name }}
        </div>
        <div
          v-if="!!game && !!game.description"
          class="welcome-text-description"
        >
          {{ game.description }}
        </div>
      </v-flex>
      <!--  -->
      <v-flex grow d-flex>
        <v-flex d-flex align-center>
          <transition-group
            name="input-check-card-transition"
            tag="div"
            style="max-height: 340px"
            class="layout fill-height justify-center row yes-no-wrap"
            ref="yesNoWrap"
          >
            <v-flex
              v-if="showVideo"
              d-flex
              class="welcome-yes-no-card"
              shrink
              :key="'video-card'"
            >
              <VideoCheckCard
                class="io-card"
                :errorHTML="videoErrorHTML"
                :videoTrack="videoTestTrack"
                :cameras="cameras"
                :camera="camera"
                @onOkay="onVideoOkay"
                @onYes="onVideoYes"
                @onNo="onVideoNo"
                @input:camera="cameraInputHandler"
              />
            </v-flex>
            <v-flex
              d-flex
              class="welcome-yes-no-card"
              shrink
              v-if="videoYes"
              :key="'mic-card'"
            >
              <MicCheckCard
                v-if="videoYes"
                class="io-card"
                :errorHTML="micErrorHTML"
                :audioTrack="audioTestTrack"
                :microphone="microphone"
                :microphones="microphones"
                @onOkay="onMicOkay"
                @onYes="onMicYes"
                @onNo="onMicNo"
                @input:microphone="microphoneInputHandler"
              />
            </v-flex>
            <v-flex
              d-flex
              class="welcome-yes-no-card"
              shrink
              v-if="micYes && videoYes"
              :key="'sound-card'"
            >
              <SoundCheckCard
                class="io-card"
                :src="audioTestSrc"
                @onYes="onSoundYes"
                @onNo="onSoundNo"
              />
            </v-flex>
            <v-flex
              d-flex
              class="welcome-yes-no-card"
              shrink
              v-if="videoYes && soundYes && client.showChooseGameCard"
              :key="'game-card'"
            >
              <ChooseTeamCard class="io-card" @onOkay="onChooseTeamOkay" />
            </v-flex>
          </transition-group>
        </v-flex>
      </v-flex>
      <v-flex
        shrink
        class="footer"
        v-if="isSuperHost || (isDebugOrigin && !showGameClosedText)"
      >
        <p class="instruction-text">
          Put on your best game face and get ready to play!
        </p>
        <button class="enter-game-btn" @click="enterGame">
          {{ enterGameText }}
        </button>
      </v-flex>
      <v-flex shrink class="footer" v-else-if="showGameClosedText">
        <p class="instruction-text">
          The game is currently closed.
          <span v-if="!!gameStartTimeText">
            Please return on
            <b>{{ gameStartTimeText }}</b>
          </span>
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex"
import VideoCheckCard from "./VideoCheckCard"
import MicCheckCard from "./MicCheckCard"
import SoundCheckCard from "./SoundCheckCard"
import ChooseTeamCard from "./ChooseTeamCard"
import UserName from "@/components/GroupTeams/Common/User/UserName"
import SvgTriangle from "@/components/Shared/SvgTriangle.vue"
import * as moment from "moment"

import { ActionTypes as TwilioModuleActionTypes } from "../../../store/TwilioModule"

import { TwilioRoom } from "@/services/twilio.service"

import { fetchTwilioToken } from "../../../services/api.service"

const TEST_AUDIO_URL = require("@/assets/celebration.mp3")
const BROWSER_ERROR_MESSAGE =
  "Sorry, Go Game Remote does not support this browser. We recommend Chrome or Firefox"
const TWILIO_CHECK = false
const TWILIO_SUBSCRIPTION_TIMEOUT = 10000
const TWILIO_TRACK_ALLOCATION_TIMEOUT = 10000
const TWILIO_VPN_ERROR_MESSAGE =
  "It looks like you are behind a firewall or VPN and therefore can't connect to the video conference. <a href='https://www.thegogame.com/virtual-game-show-tech-support'>Troubleshooting</a>."
const TWILIO_TOCKEN_ERROR_MESSAGE = "Cannot get Twilio tokens"

export default {
  name: "onboard",
  components: {
    VideoCheckCard,
    MicCheckCard,
    SoundCheckCard,
    ChooseTeamCard,
    UserName,
    SvgTriangle
  },
  data() {
    return {
      videoErrorHTML: "",
      micErrorHTML: "",
      showVideo: false,
      videoYes: false,
      micYes: false,
      soundYes: false,
      state: "welcome",
      audioTestTrack: null,
      videoTestTrack: null,
      localVideoTrack: null,
      localConnection: null,
      remoteConnection: null,
      audioTestSrc: null,
      timeZone: null
    }
  },
  async created() {
    if (this.client.showChooseGameCard) {
      try {
        await this.$store.dispatch("pregame/fetchUserPlayedGames", {
          clientID: this.clientID,
          userID: this.user.id
        })
      } catch (e) {
        console.error(e)
      }
    }
    try {
      await this.updateUser({ userID: this.user.id, obj: { onboarding: true } })
    } catch (e) {
      console.warn(e)
    }
    try {
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (e) {
      console.warn(e)
    }
  },
  watch: {
    // Check the mic right after we have positive for
    // the video feed
    videoYes(newValue) {
      if (newValue) this.startMic()
    },
    micYes(newValue) {
      if (newValue) this.audioTestSrc = TEST_AUDIO_URL
    }
  },
  beforeDestroy() {
    this.suspendConnection()
  },
  computed: {
    ...mapState("twilio", ["cameras", "camera", "microphones", "microphone"]),
    ...mapState({ gameID: state => state.gameID }),
    ...mapGetters(["user", "game", "isDebugOrigin"]),
    ...mapGetters("auth", ["isHost", "isAudit", "clientID"]),
    ...mapGetters("auth", ["hasPreGame", "client"]),
    enterGameText() {
      if (!this.hasPreGame || (this.user.gameID && this.gameStarted)) {
        return "ENTER GAME"
      } else {
        return "ENTER EXPO"
      }
    },
    gameStarted() {
      return this.game ? !!this.game.started : false
    },
    gameStartTimeFormatted() {
      if (!this.gameStartTime) return null
      const text = moment(this.gameStartTime).format("MM/DD/YYYY hh:mm")
      return this.timeZone ? `${text} ${this.timeZone}` : text
    },
    gameStartTimeBackupText() {
      return this.game ? this.game.date : null
    },
    gameStartTimeText() {
      return this.gameStartTimeBackupText
        ? this.gameStartTimeBackupText
        : this.gameStartTimeFormatted
    },
    isClientLocked() {
      return this.client ? !!this.client.locked : false
    },
    gameStartTime() {
      return this.game && this.user.gameID ? this.game.startTimestamp : 0
    },
    isSuperHost() {
      return this.isHost || this.user.lastname.includes("kelly")
    },
    isGameLocked() {
      return this.game ? !!this.game.locked : true
    },
    showGameClosedText() {
      return this.hasPreGame ? !!this.isClientLocked : !!this.isGameLocked
    }
  },
  mounted() {
    setTimeout(this.startIntro, 300)
  },
  methods: {
    ...mapActions("twilio", [
      TwilioModuleActionTypes.SET_CAMERA,
      TwilioModuleActionTypes.SET_MICROPHONE,
      TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK,
      TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
    ]),
    ...mapActions("test", ["getCameraErrorMessage", "getMicErrorMessage"]),
    ...mapActions(["updateUser", "updateIgnoreOnboardingSelect"]),
    ...mapActions("auth", ["updateLastKnownCheck"]),
    startIntro() {
      this.showVideo = true
      this.startVideo()
    },
    suspendConnection() {
      if (this.localConnection) this.localConnection.disconnect()
      if (this.remoteConnection) this.remoteConnection.disconnect()
      if (this.audioTestTrack) this.audioTestTrack.stop()
      if (this.videoTestTrack && this.videoTestTrack.stop)
        this.videoTestTrack.stop()

      if (this.localVideoTrack !== null) {
        this.localVideoTrack.stop()
      }
    },
    onMicOkay() {
      this.micErrorHTML = ""
      this.startMic()
    },
    onVideoOkay() {
      this.videoErrorHTML = ""
      this.startVideo()
    },
    onSoundYes() {
      this.soundYes = true
      this.scrollRight()
      if (!this.client.showChooseGameCard) this.enterGame()
    },
    onSoundNo() {
      this.soundYes = false
    },
    onChooseTeamOkay() {
      this.enterGame()
    },
    onVideoYes() {
      this.videoYes = true
      this.scrollRight()
    },
    onVideoNo() {
      this.videoYes = false
    },
    onMicYes() {
      this.micYes = true
      this.scrollRight()
    },
    onMicNo() {
      this.micYes = false
    },
    async startMic() {
      try {
        const track = await this[
          TwilioModuleActionTypes.CREATE_LOCAL_AUDIO_TRACK
        ]()
        this.audioTestTrack = track
      } catch (e) {
        console.error(e)
        this.micErrorHTML = await this.getMicErrorMessage({ error: e })
      }
    },
    getTimeout(delay) {
      return new Promise((resolve, reject) =>
        setTimeout(reject, delay, new Error("Timeout"))
      )
    },
    async startVideo() {
      this.videoTestTrack = null
      this.suspendConnection()
      try {
        let localTrack
        if (!TwilioRoom.isSupportedBrowser())
          throw new Error(BROWSER_ERROR_MESSAGE)
        try {
          // throw new Error("Failed to allocate videosource")
          // throw new Error("Could not start video source")
          // throw new Error("Permission denied by system")
          // throw new Error("Permission denied")
          // throw new Error("")
          const foo = async () => {
            await this.$devices.tryToEnumerateDevices()
            return await this[
              TwilioModuleActionTypes.CREATE_LOCAL_VIDEO_TRACK
            ]()
          }
          localTrack = await Promise.race([
            foo(),
            this.getTimeout(TWILIO_TRACK_ALLOCATION_TIMEOUT)
          ])
          this.localVideoTrack = localTrack
        } catch (e) {
          const message = await this.getCameraErrorMessage({ error: e })
          throw new Error(message)
        }

        if (TWILIO_CHECK) {
          const token = this.$store.state.auth.token
          const userID = this.$store.state.auth.user.id

          let twilioTokens = null

          try {
            twilioTokens = await Promise.all([
              fetchTwilioToken({ token, userID: `${userID}-test` }),
              fetchTwilioToken({ token, userID })
            ])
          } catch (e) {
            console.error(e)
            throw new Error(TWILIO_TOCKEN_ERROR_MESSAGE)
          }

          if (twilioTokens.length < 2)
            throw new Error(TWILIO_TOCKEN_ERROR_MESSAGE)

          try {
            const name = userID
            const videoTrack = localTrack
            const connections = await Promise.all([
              TwilioRoom.connectToRoom({
                token: twilioTokens[0],
                name,
                videoTrack
              }),
              TwilioRoom.connectToRoom({ token: twilioTokens[1], name })
            ])
            this.remoteConnection = connections[0]
            this.localConnection = connections[1]
          } catch (e) {
            console.error(e)
            throw new Error(TWILIO_VPN_ERROR_MESSAGE)
          }

          const subscribeToParticipants = room => {
            return new Promise((resolve, reject) => {
              const error = new Error("Twilio subscription timeout error")
              const timeout = setTimeout(
                () => reject(error),
                TWILIO_SUBSCRIPTION_TIMEOUT
              )
              const onTrackSubscribed = track => {
                clearTimeout(timeout)
                resolve(track)
              }
              const onParticipantConnected = participant => {
                participant.on("trackSubscribed", onTrackSubscribed)
              }
              room.on("participantConnected", onParticipantConnected)
              room.participants.forEach(onParticipantConnected)
            })
          }

          const remoteTrack = await subscribeToParticipants(
            this.localConnection
          )
          this.videoTestTrack = remoteTrack
        } else {
          this.videoTestTrack = localTrack
        }
      } catch (e) {
        this.videoErrorHTML = e.message
        return console.error(e)
      }
    },
    enterGame() {
      this.updateLastKnownCheck(Date.now())
    },
    scrollRight() {
      const yesNoWrap = this.$refs.yesNoWrap.$el
      setTimeout(() => {
        yesNoWrap.scrollTo({
          left: yesNoWrap.scrollWidth + 10,
          behavior: "smooth"
        })
      }, 700)
    },
    cameraInputHandler(id) {
      this.suspendConnection()
      this[TwilioModuleActionTypes.SET_CAMERA](id)
      this.startVideo()
    },
    microphoneInputHandler(id) {
      this[TwilioModuleActionTypes.SET_MICROPHONE](id)
      this.startMic()
    },
    logout() {
      if (confirm("Are you sure you want LOGOUT?")) {
        this.$router.push("/logout")
      }
    }
  }
}
</script>

<style lang="scss">
.rtb-welcome-page {
  margin: 0;
  padding: 0;
  background-color: $color-secondary-dark;
  display: flex;
  padding-top: 20px;
  padding-bottom: 18px;
  position: relative;
  background-image: url("../../../assets/honeycomb-texture.png");
  background-repeat: repeat;
  background-size: 35px;
  overflow: hidden;

  .welcome-yes-no-card {
    margin: 0 10px;
    width: 33.3%;
    max-width: 260px;
    min-height: 150px;
  }
  .io-card {
    opacity: 1;
    transform: scale(1);
    transition: all 0.7s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.6s;
  }
  .input-check-card-transition-enter-active {
    transition: all 0.5s ease 0.1s;
  }
  .input-check-card-transition-leave-active {
    transition: all 0.5s ease 0.6s;
    .io-card {
      transition: all 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
    }
  }
  .input-check-card-transition-enter,
  .input-check-card-transition-leave-to {
    min-width: 0 !important;
    max-width: 0 !important;
    margin: 0 !important;
    .io-card {
      opacity: 0;
      transform: scale(0.3);
    }
  }

  .team-tag-team-name {
    display: inline-block;
    background-color: inherit;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 2;
    margin: auto 80px;
    font-size: 22px;
  }
  .team-tag-team {
    background-color: $primary_accent_color;
    z-index: 3;
  }
  .team-tag {
    display: inline-flex;
    flex-direction: row;
    position: relative;
    color: $color-grey-light1;
    font-size: 18px;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    margin: auto;
    margin-top: -15px;
  }
  .team-shape-left,
  .team-shape-right {
    width: auto;
    height: 100%;
    position: absolute;
  }
  .team-shape-left {
    right: 100%;
    right: calc(100% - 1px);
  }
  .team-shape-right {
    left: 100%;
    left: calc(100% - 1px);
    transform: rotate(180deg);
  }
  .audit-label {
    max-width: 250px;
    margin: -7px auto 7px;
    text-align: center;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba($color-black, 0.1) 50%,
      transparent 100%
    );
    &:after {
      content: "";
      display: block;
      height: 1px;
      opacity: 0.25;
      background: linear-gradient(
        to right,
        transparent 0%,
        $color-white 50%,
        transparent 100%
      );
    }
    span {
      display: inline-block;
      vertical-align: middle;
      line-height: 25px;
      font-size: 10px;
      color: $color-white;
      text-transform: uppercase;
      background: url("../../../assets/audit-icon.png") no-repeat left center;
      background-size: 15px;
      padding-left: 20px;
    }
  }
  .welcome-text {
    color: $color-white;
    font-size: 30px;
    font-weight: bold;
    padding: 0 15px;
  }
  .welcome-text-description {
    color: $color-white;
    font-size: 22px;
    padding: 0 15px;
  }
  .instruction-text {
    color: $color-white;
    font-size: 17px;
    padding: 0 15px;
  }
  .enter-game-btn {
    @extend .rtb-border-radius;
    background-color: $primary_accent_color !important;
    color: $color-white;
    padding: 2px 20px;
    font-size: 16px;
    font-weight: bold;
  }
  .enter-game-btn:hover {
    filter: brightness(1.2);
  }
  .header {
    padding: 22px 0;
  }
  .footer {
    padding: 22px 0;
  }
  .rtb-logo-wrap {
    padding-bottom: 30px;
    border-bottom: 2px solid $primary_accent_color;
  }
  .yes-no-wrap {
    position: relative;
  }
  .logout {
    position: absolute;
    top: 12px;
    right: 12px;
    height: 22px;
    line-height: 21px;
    font-size: 12px;
    white-space: nowrap;
    border: solid 1px $primary_accent_color;
    border-radius: 12px;
    color: $color-grey-light4;
    padding: 0 15px;
    cursor: pointer;

    &:hover {
      color: $color-white;
      background: $primary_accent_color;
    }
  }
}
@media (max-width: 767px) {
  .rtb-welcome-page {
    .instruction-text {
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
    }
    .welcome-yes-no-card {
      min-width: 260px;
      margin: 0 auto 15px;
      &:first-child {
        padding-left: 10px;
      }
      &:last-child {
        padding-right: 10px;
      }
    }
    .yes-no-wrap {
      overflow: auto;
      justify-content: flex-start;
    }
  }
}
</style>
