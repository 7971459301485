var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "yes-no-card" },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "" } },
        [
          _c(
            "v-flex",
            { attrs: { shrink: "" } },
            [
              _c(
                "v-layout",
                { attrs: { row: "" } },
                [
                  _vm.showIcon
                    ? _c(
                        "v-flex",
                        { staticClass: "icon-wrap", attrs: { shrink: "" } },
                        [_vm._t("icon")],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-flex",
                    {
                      staticClass: "title-text",
                      staticStyle: { display: "flex" },
                      attrs: { "align-center": "" }
                    },
                    [
                      _vm.mode === "photo" || _vm.mode === "save"
                        ? _c("v-flex", [_vm._v(" Game Avatar ")])
                        : _c("v-flex", [_vm._v(" " + _vm._s(_vm.title) + " ")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { grow: "", "d-flex": "" } },
            [_c("v-flex", { attrs: { "d-flex": "" } }, [_vm._t("default")], 2)],
            1
          ),
          _vm.mode === "photo"
            ? _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.textHTML
                    ? _c("div", { staticClass: "question-text" }, [
                        _vm._v(" Take or upload your game photo ")
                      ])
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "yes-no-btn",
                          class: { yes: false },
                          attrs: { xs5: "", "mr-2": "", shrink: "" },
                          on: { click: _vm.onUpload }
                        },
                        [_vm._v(" UPLOAD ")]
                      ),
                      _c(
                        "v-flex",
                        {
                          staticClass: "yes-no-btn",
                          class: { yes: _vm.isCountdownOn },
                          attrs: { xs5: "", shrink: "" },
                          on: { click: _vm.onReady }
                        },
                        [_vm._v(" PHOTO ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.mode === "selecting"
            ? _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.textHTML
                    ? _c("div", { staticClass: "question-text" }, [
                        _vm._v("Select your game avatar")
                      ])
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "yes-no-btn",
                          class: { yes: false },
                          attrs: { shrink: "" },
                          on: { click: _vm.onApply }
                        },
                        [_vm._v(" APPLY ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.mode === "upload"
            ? _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.textHTML
                    ? _c("div", { staticClass: "question-text" }, [
                        _vm._v("Select your game avatar")
                      ])
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "", row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "yes-no-btn",
                          class: { yes: false },
                          attrs: { xs4: "", shrink: "" },
                          on: { click: _vm.onSelect }
                        },
                        [_vm._v(" SELECT ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.mode === "save" || _vm.mode === "file-save"
            ? _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.textHTML
                    ? _c(
                        "div",
                        { staticClass: "question-text" },
                        [
                          _vm.mode === "file-save"
                            ? [_vm._v(" Is the photo OK? ")]
                            : [_vm._v(" Is the image OK? ")]
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-layout",
                    { attrs: { "justify-space-around": "", row: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticClass: "yes-no-btn",
                          class: { yes: _vm.isPhotoGood },
                          attrs: { shrink: "" },
                          on: { click: _vm.onSave }
                        },
                        [_vm._v("YES")]
                      ),
                      _vm.MAX_CAPTURE_AVATAR_ATTEMPTS - _vm.attempts !== 0
                        ? _c(
                            "v-flex",
                            {
                              staticClass: "yes-no-btn",
                              attrs: { shrink: "" },
                              on: { click: _vm.onDiscard }
                            },
                            [_vm._v(" NO ")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.mode === "chooseGame"
            ? _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.showOkay
                    ? _c(
                        "div",
                        {
                          staticClass: "yes-no-btn okay",
                          on: { click: _vm.onOkay }
                        },
                        [_vm._v(" JOIN THE GAME ")]
                      )
                    : _vm._e()
                ]
              )
            : _c(
                "v-flex",
                { staticClass: "question-row", attrs: { shrink: "" } },
                [
                  _vm.textHTML
                    ? _c("div", {
                        staticClass: "question-text",
                        domProps: { innerHTML: _vm._s(_vm.textHTML) }
                      })
                    : _vm._e(),
                  _vm.showOkay
                    ? _c(
                        "div",
                        {
                          staticClass: "yes-no-btn okay",
                          on: { click: _vm.onOkay }
                        },
                        [_vm._v(" TRY AGAIN ")]
                      )
                    : _vm.showYesNo
                    ? _c(
                        "v-layout",
                        { attrs: { "justify-space-around": "", row: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "yes-no-btn",
                              class: { yes: _vm.yes },
                              attrs: { shrink: "" },
                              on: { click: _vm.onYes }
                            },
                            [_vm._v("YES")]
                          ),
                          _c(
                            "v-flex",
                            {
                              staticClass: "yes-no-btn",
                              attrs: { shrink: "" },
                              on: { click: _vm.onNo }
                            },
                            [_vm._v("NO")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }