<template>
  <YesNoCard
    class="sound-check-card"
    :title="'Headphone Check'"
    :textHTML="textHTML"
    :showYesNo="(!response || response === 'yes') && playedOnce"
    :showOkay="response === 'no'"
    @onYes="onYes"
    @onNo="onNo"
    @onOkay="onOkay"
  >
    <template v-slot:icon>
      <v-icon>fas fa-headphones</v-icon>
    </template>
    <v-flex d-flex align-center v-if="src">
      <div v-if="isPlaying">
        <img
          width="200"
          src="https://media.giphy.com/media/3o85xCV3NjTFuLRa6s/giphy.gif"
        />
      </div>
      <div v-else>
        <div class="required-text">
          Please put your<br />headphones on now<br />(optional)
        </div>
        <div @click="onPlay" class="play-btn">
          <v-icon>play_arrow</v-icon>
        </div>
        <div class="play-text">
          Click to test<br />
          your audio
        </div>
      </div>

      <audio ref="audio" @ended="onEnd">
        <source :src="src" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </v-flex>
  </YesNoCard>
</template>

<script>
import YesNoCard from "@/components/GroupTeams/Common/YesNoCard"
export default {
  name: "SoundCheckCard",
  components: {
    YesNoCard
  },
  props: {
    src: String
  },
  data() {
    return {
      response: null,
      isPlaying: false,
      playedOnce: false
    }
  },
  computed: {
    textHTML() {
      if (this.response === "yes") {
        return "You are ready to rock!"
      } else if (this.response === "no") {
        return "Sorry, we can't detect any issues automatically. Try <a target='_blank' href='https://www.thegogame.com/virtual-game-show-tech-support'>here</a> for sound support, and then refresh your browser."
      } else if (this.playedOnce) {
        return "Can you hear the audio?"
      } else {
        return ""
      }
    }
  },
  methods: {
    onEnd() {
      this.isPlaying = false
    },
    onPlay() {
      this.playedOnce = true
      if (this.$refs.audio) {
        if (this.$refs.audio.paused) {
          this.$refs.audio.volume = 0.22
          this.$refs.audio.play()
          this.isPlaying = true
        }
      }
    },
    onYes() {
      this.response = "yes"
      this.$emit("onYes")
    },
    onNo() {
      this.response = "no"
      this.$emit("onNo")
    },
    onOkay() {
      this.response = null
      this.$emit("onOkay")
    }
  }
}
</script>
<style lang="scss">
.sound-check-card {
  .spinner {
    color: $primary_accent_color;
  }
  .play-text {
    color: $primary_accent_color;
    font-weight: bold;
  }
  .required-text {
    padding-bottom: 20px;
  }
  .play-btn {
    user-select: none;
    margin: auto;
    cursor: pointer;
    display: inline-block;
    margin: 7px;
    padding: 3px 16px;
    border-radius: 10px;
    background-color: $primary_accent_color;
    border: 3px solid $color-white;
    &:hover {
      filter: brightness(1.2);
    }
    i {
      font-size: 30px;
      color: $color-white !important;
    }
  }
}
</style>
