<template>
  <YesNoCard
    class="audio-check-card"
    :title="'Microphone Check'"
    :textHTML="textHTML"
    :showYesNo="(!response || response === 'yes') && !!audioTrack"
    :showOkay="(errorHTML ? true : false) || response === 'no'"
    @onYes="onYes"
    @onNo="onNo"
    @onOkay="onOkay"
  >
    <template v-slot:icon>
      <v-icon>fas fa-microphone</v-icon>
    </template>
    <v-flex d-flex>
      <v-layout column>
        <v-flex shrink v-if="microphones.length > 0">
          <div class="video-check-card__select-wrap">
            <v-select
              :value="microphone"
              :items="normalizedMicrophones"
              dense
              hide-details
              class="video-check-card__select v-select--theme--default"
              @input="microphoneInputHandler"
            />
          </div>
        </v-flex>
        <v-flex d-flex align-center>
          <v-flex v-show="!!audioTrack" class="sound-indicator-wrap">
            <div class="sound-indicator-border left">
              <div class="sound-grid">
                <span v-for="i in 20" :key="`span-1-${i}`" />
              </div>
              <div class="sound-indicator" ref="left_bar"></div>
            </div>
            <div class="sound-indicator-border right">
              <div class="sound-grid">
                <span v-for="i in 20" :key="`span-2-${i}`" />
              </div>
              <div class="sound-indicator" ref="right_bar"></div>
            </div>
          </v-flex>
        </v-flex>
      </v-layout>
    </v-flex>
    <audio ref="audio" />
  </YesNoCard>
</template>

<script>
import { TweenMax } from "gsap/TweenMax"
import YesNoCard from "@/components/GroupTeams/Common/YesNoCard"
export default {
  name: "MicCheckCard",
  components: {
    YesNoCard
  },
  props: {
    audioTrack: Object,
    errorHTML: String,
    microphones: {
      type: Array
    },
    microphone: {
      type: String
    }
  },
  data() {
    return {
      response: null,
      foo: true,
      analyser: null,
      dataArray: null
    }
  },
  computed: {
    textHTML() {
      if (this.errorHTML) {
        return this.errorHTML
      } else if (this.response === "yes") {
        return "Well done!"
      } else if (this.response === "no") {
        return "Sorry, we can't detect any issues automatically. Try <a target='_blank' href='https://www.thegogame.com/virtual-game-show-tech-support'>here</a> for mic support, and then refresh your browser."
      } else if (this.audioTrack) {
        return "Is your mic working? You must see bouncing lights"
      } else {
        return ""
      }
    },
    /**
     * @description Normalizes microphones to render as items in select.
     * @see https://stackoverflow.com/questions/60297972/navigator-mediadevices-enumeratedevices-returns-empty-labels
     */
    normalizedMicrophones() {
      return this.microphones.map((microphone, index) => ({
        value: microphone.deviceId,
        text:
          microphone.label === "" ? `Microphone ${index + 1}` : microphone.label
      }))
    }
  },
  created() {
    TweenMax.ticker.addEventListener("tick", this.onTick)
  },
  beforeDestroy() {
    TweenMax.ticker.removeEventListener("tick", this.onTick)
    if (this.audioTrack) this.audioTrack.detach(this.$refs.audio)
  },
  watch: {
    audioTrack(newValue, oldValue) {
      if (oldValue && oldValue !== newValue) {
        oldValue.detach(this.$refs.audio)
      }
      if (newValue && newValue !== oldValue) {
        newValue.attach(this.$refs.audio)
        try {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)()
          // create a source from our MediaStream
          const source = audioContext.createMediaStreamSource(
            this.$refs.audio.srcObject
          )
          // create an analyser object
          this.analyser = audioContext.createAnalyser()
          // how often to update
          this.analyser.fftSize = 256
          const bufferLength = this.analyser.frequencyBinCount
          this.dataArray = new Uint8Array(bufferLength)
          source.connect(this.analyser)
        } catch (e) {
          console.error(e.message)
        }
      }
    }
  },
  methods: {
    onTick() {
      if (this.analyser && this.dataArray) {
        const max = Math.max.apply(null, this.dataArray)
        this.analyser.getByteFrequencyData(this.dataArray)
        const percentage = parseInt((max / 255) * 100)
        if (this.$refs.left_bar)
          this.$refs.left_bar.style.height = 100 - percentage + "%"
        if (this.$refs.right_bar)
          this.$refs.right_bar.style.height = 100 - percentage + "%"
      }
    },
    onYes() {
      this.response = "yes"
      this.$emit("onYes")
    },
    onNo() {
      this.response = "no"
      this.$emit("onNo")
    },
    onOkay() {
      this.response = null
      this.$emit("onOkay")
    },
    microphoneInputHandler(id) {
      this.response = null
      this.$emit("input:microphone", id)
    }
  }
}
</script>
<style lang="scss">
.audio-check-card {
  .sound-indicator-wrap {
    position: relative;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 148px;
  }
  .sound-grid {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    span {
      display: block;
      position: relative;
      box-sizing: border-box;
      border: solid 1px $color-swamp-dark-20-opacity;
      border-bottom: none;
      height: 5%;
      width: 100%;
      &:last-child {
        border-bottom: solid 1px $color-swamp-dark-20-opacity;
      }
    }
  }
  .sound-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba($color-secondary-dark, 0.9);
    border-bottom: solid 1px rgba($color-primary-accent, 0.8);
  }
  .sound-indicator-border {
    height: 100%;
    width: 20px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border: 3px solid $color-tertiary-dark;
    overflow: hidden;
    background: linear-gradient(
      to bottom,
      $color-red 0%,
      $secondary_accent_color 50%,
      $color-correct 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

    &.left {
      margin-left: -20px;
    }
    &.right {
      margin-left: 20px;
    }
  }
}

.mic-check-card {
  &__select-wrap {
    width: 60%;
    margin: {
      top: -4px;
      right: auto;
      left: auto;
    }
  }

  &__select {
    .v-input input {
      height: 24px;
    }

    .v-select__selection {
      padding-left: 0;
      font-size: 12px;
    }

    .v-input__slot:before {
      display: none;
    }
  }
}
</style>
