<template>
  <v-flex class="yes-no-card">
    <v-layout column fill-height>
      <v-flex shrink>
        <v-layout row>
          <v-flex shrink v-if="showIcon" class="icon-wrap">
            <slot name="icon"></slot>
          </v-flex>
          <v-flex style="display: flex" align-center class="title-text">
            <v-flex v-if="mode === 'photo' || mode === 'save'">
              Game Avatar
            </v-flex>
            <v-flex v-else>
              {{ title }}
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex grow d-flex>
        <v-flex d-flex>
          <slot></slot>
        </v-flex>
      </v-flex>
      <v-flex shrink class="question-row" v-if="mode === 'photo'">
        <div v-if="textHTML" class="question-text">
          Take or upload your game photo
        </div>
        <v-layout justify-center row>
          <v-flex
            xs5
            mr-2
            shrink
            class="yes-no-btn"
            :class="{ yes: false }"
            @click="onUpload"
          >
            UPLOAD
          </v-flex>
          <v-flex
            xs5
            shrink
            class="yes-no-btn"
            :class="{ yes: isCountdownOn }"
            @click="onReady"
          >
            PHOTO
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex shrink class="question-row" v-else-if="mode === 'selecting'">
        <div v-if="textHTML" class="question-text">Select your game avatar</div>
        <v-layout justify-center row>
          <v-flex
            shrink
            class="yes-no-btn"
            :class="{ yes: false }"
            @click="onApply"
          >
            APPLY
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex shrink class="question-row" v-else-if="mode === 'upload'">
        <div v-if="textHTML" class="question-text">Select your game avatar</div>
        <v-layout justify-center row>
          <v-flex
            xs4
            shrink
            class="yes-no-btn"
            :class="{ yes: false }"
            @click="onSelect"
          >
            SELECT
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex
        shrink
        class="question-row"
        v-else-if="mode === 'save' || mode === 'file-save'"
      >
        <div v-if="textHTML" class="question-text">
          <template v-if="mode === 'file-save'"> Is the photo OK? </template>
          <template v-else> Is the image OK? </template>
        </div>
        <v-layout justify-space-around row>
          <v-flex
            shrink
            class="yes-no-btn"
            :class="{ yes: isPhotoGood }"
            @click="onSave"
            >YES</v-flex
          >
          <v-flex
            v-if="MAX_CAPTURE_AVATAR_ATTEMPTS - attempts !== 0"
            shrink
            class="yes-no-btn"
            @click="onDiscard"
          >
            NO
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex shrink class="question-row" v-else-if="mode === 'chooseGame'">
        <div v-if="showOkay" class="yes-no-btn okay" @click="onOkay">
          JOIN THE GAME
        </div>
      </v-flex>
      <v-flex shrink class="question-row" v-else>
        <div v-if="textHTML" class="question-text" v-html="textHTML" />
        <div v-if="showOkay" class="yes-no-btn okay" @click="onOkay">
          TRY AGAIN
        </div>
        <v-layout justify-space-around row v-else-if="showYesNo">
          <v-flex shrink class="yes-no-btn" :class="{ yes: yes }" @click="onYes"
            >YES</v-flex
          >
          <v-flex shrink class="yes-no-btn" @click="onNo">NO</v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { MAX_CAPTURE_AVATAR_ATTEMPTS } from "@/config"

export default {
  name: "YesNoCard",
  props: {
    showOkay: {
      type: Boolean,
      default: false
    },
    showYesNo: {
      type: Boolean,
      default: true
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    textHTML: String,
    title: String,
    mode: String,
    attempts: {
      type: Number
    }
  },
  data() {
    return {
      yes: false,
      isCountdownOn: false,
      isPhotoGood: false,
      MAX_CAPTURE_AVATAR_ATTEMPTS
    }
  },
  methods: {
    onApply() {
      this.$emit("onApply")
    },
    onSelect() {
      this.$emit("onSelect")
    },
    onSave() {
      this.isPhotoGood = true
      this.$emit("onSave")
    },
    onDiscard() {
      this.isPhotoGood = false
      this.$emit("onDiscard")
    },
    onYes() {
      this.yes = true
      this.$emit("onYes")
    },
    onNo() {
      this.yes = false
      this.$emit("onNo")
    },
    onOkay() {
      this.$emit("onOkay")
    },
    onReady() {
      this.isCountdownOn = true
      this.$emit("onReady")
    },
    onUpload() {
      // this.isCountdownOn = true
      this.$emit("onUpload")
    }
  }
}
</script>
<style lang="scss">
.yes-no-card {
  @extend .rtb-border-radius;
  line-height: 1;
  background-color: $color-primary-dark;
  overflow: hidden;
  border: 3px solid $color-primary-dark;
  & > div {
    position: relative;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $color-secondary-light;
  }
  .title-text {
    font-weight: bold;
    font-size: 18px;
  }
  .question-row {
    transition: opacity 0.3s;
    padding: 10px;
  }
  .question-text {
    font-size: 15px;
    margin-bottom: 12px;
  }
  .yes-no-btn {
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    background-color: $color-white;
    padding: 3px 8px;
    font-size: 15px;
    font-weight: bold;
    user-select: none;
    color: $primary_accent_color;
    &.yes {
      background-color: $secondary_accent_color;
      color: $color-white;
      pointer-events: none;
    }
    &.okay {
      margin: auto;
    }
    &:hover {
      filter: brightness(1.2);
    }
  }
  .icon-wrap {
    background-color: $color-primary-dark;
    padding: 7px;
    padding-left: 6px;
    padding-top: 6px;
    min-width: 2.5em;
    min-height: 2.5em;
    border-radius: 0 0 6px 0;
    i {
      font-size: 1.5em;
      color: $color-white !important;
    }
  }
}
</style>
