var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "rtb-welcome-page",
      staticStyle: { "z-index": "1000" },
      attrs: {
        "ma-0": "",
        "pa-0": "",
        "fill-height": "",
        fluid: "",
        "text-xs-center": ""
      }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            { staticClass: "header", attrs: { shrink: "" } },
            [
              _c("v-layout", [
                _c(
                  "div",
                  {
                    staticClass: "logout",
                    on: {
                      click: function($event) {
                        return _vm.logout()
                      }
                    }
                  },
                  [_vm._v("LOG OUT")]
                )
              ]),
              _c("div", { staticClass: "rtb-logo-wrap" }, [
                _c("img", {
                  attrs: { width: "270", src: _vm.$theme.getImage("loginLogo") }
                })
              ]),
              _c("div", { staticClass: "team-tag" }, [
                _c(
                  "div",
                  { staticClass: "team-tag-team" },
                  [
                    _c("SvgTriangle", { staticClass: "team-shape-left" }),
                    _c(
                      "div",
                      { staticClass: "team-tag-team-name" },
                      [
                        !!_vm.user
                          ? _c("UserName", {
                              attrs: {
                                firstname: _vm.user.firstname,
                                lastname: _vm.user.lastname
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("SvgTriangle", { staticClass: "team-shape-right" })
                  ],
                  1
                )
              ]),
              _vm.isAudit
                ? _c("div", { staticClass: "audit-label" }, [
                    _c("span", [_vm._v("Auditing")])
                  ])
                : _vm._e(),
              !!_vm.game
                ? _c("div", { staticClass: "welcome-text" }, [
                    _vm._v(
                      " Welcome to " +
                        _vm._s(_vm.game.externalName || _vm.game.name) +
                        " "
                    )
                  ])
                : _vm._e(),
              !!_vm.game && !!_vm.game.description
                ? _c("div", { staticClass: "welcome-text-description" }, [
                    _vm._v(" " + _vm._s(_vm.game.description) + " ")
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "v-flex",
            { attrs: { grow: "", "d-flex": "" } },
            [
              _c(
                "v-flex",
                { attrs: { "d-flex": "", "align-center": "" } },
                [
                  _c(
                    "transition-group",
                    {
                      ref: "yesNoWrap",
                      staticClass:
                        "layout fill-height justify-center row yes-no-wrap",
                      staticStyle: { "max-height": "340px" },
                      attrs: { name: "input-check-card-transition", tag: "div" }
                    },
                    [
                      _vm.showVideo
                        ? _c(
                            "v-flex",
                            {
                              key: "video-card",
                              staticClass: "welcome-yes-no-card",
                              attrs: { "d-flex": "", shrink: "" }
                            },
                            [
                              _c("VideoCheckCard", {
                                staticClass: "io-card",
                                attrs: {
                                  errorHTML: _vm.videoErrorHTML,
                                  videoTrack: _vm.videoTestTrack,
                                  cameras: _vm.cameras,
                                  camera: _vm.camera
                                },
                                on: {
                                  onOkay: _vm.onVideoOkay,
                                  onYes: _vm.onVideoYes,
                                  onNo: _vm.onVideoNo,
                                  "input:camera": _vm.cameraInputHandler
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.videoYes
                        ? _c(
                            "v-flex",
                            {
                              key: "mic-card",
                              staticClass: "welcome-yes-no-card",
                              attrs: { "d-flex": "", shrink: "" }
                            },
                            [
                              _vm.videoYes
                                ? _c("MicCheckCard", {
                                    staticClass: "io-card",
                                    attrs: {
                                      errorHTML: _vm.micErrorHTML,
                                      audioTrack: _vm.audioTestTrack,
                                      microphone: _vm.microphone,
                                      microphones: _vm.microphones
                                    },
                                    on: {
                                      onOkay: _vm.onMicOkay,
                                      onYes: _vm.onMicYes,
                                      onNo: _vm.onMicNo,
                                      "input:microphone":
                                        _vm.microphoneInputHandler
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.micYes && _vm.videoYes
                        ? _c(
                            "v-flex",
                            {
                              key: "sound-card",
                              staticClass: "welcome-yes-no-card",
                              attrs: { "d-flex": "", shrink: "" }
                            },
                            [
                              _c("SoundCheckCard", {
                                staticClass: "io-card",
                                attrs: { src: _vm.audioTestSrc },
                                on: {
                                  onYes: _vm.onSoundYes,
                                  onNo: _vm.onSoundNo
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.videoYes &&
                      _vm.soundYes &&
                      _vm.client.showChooseGameCard
                        ? _c(
                            "v-flex",
                            {
                              key: "game-card",
                              staticClass: "welcome-yes-no-card",
                              attrs: { "d-flex": "", shrink: "" }
                            },
                            [
                              _c("ChooseTeamCard", {
                                staticClass: "io-card",
                                on: { onOkay: _vm.onChooseTeamOkay }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.isSuperHost || (_vm.isDebugOrigin && !_vm.showGameClosedText)
            ? _c("v-flex", { staticClass: "footer", attrs: { shrink: "" } }, [
                _c("p", { staticClass: "instruction-text" }, [
                  _vm._v(" Put on your best game face and get ready to play! ")
                ]),
                _c(
                  "button",
                  {
                    staticClass: "enter-game-btn",
                    on: { click: _vm.enterGame }
                  },
                  [_vm._v(" " + _vm._s(_vm.enterGameText) + " ")]
                )
              ])
            : _vm.showGameClosedText
            ? _c("v-flex", { staticClass: "footer", attrs: { shrink: "" } }, [
                _c("p", { staticClass: "instruction-text" }, [
                  _vm._v(" The game is currently closed. "),
                  !!_vm.gameStartTimeText
                    ? _c("span", [
                        _vm._v(" Please return on "),
                        _c("b", [_vm._v(_vm._s(_vm.gameStartTimeText))])
                      ])
                    : _vm._e()
                ])
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }